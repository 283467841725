import React, { useEffect, useState } from "react";
import {  Form } from "reactstrap";

// components
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";

// interface
import { MESSAGE_TYPES, SENDER_TYPES } from "../../../../constants";
import { useRedux } from "../../../../hooks";
import { createSelector } from "reselect";

interface IndexProps {
  onSend: (data: any) => void;
  chatUserDetails: any;
  webSocket: any;
  onClickStart: () => void;
  onClickStop: () => void;
  mediaRecorder: MediaRecorder;
  recordedAudioBase64: string;
  messagesList: any;
  wait: boolean;
}


const Index = ({
  onSend,
  chatUserDetails,
  webSocket,
  mediaRecorder,
  recordedAudioBase64,
  onClickStart,
  onClickStop,
  messagesList,
  wait,
}: IndexProps) => {

  const handleRecorderClick = () => {
    switch (mediaRecorder?.state) {
      case "inactive":
        onClickStart()
        break;
      case "recording":
        onClickStop()
        break;
      default:
        onClickStart()
        break;
    }
  }

  useEffect(() => {
    if (recordedAudioBase64) {
     const  lastMessage = messagesList[messagesList.length - 1]
      let data: any = {
        "sender_type": SENDER_TYPES.USER,
        "message_type": lastMessage && lastMessage.message_type === MESSAGE_TYPES.COMPLETE_DESCRIPTION_FREE_TEXT ? MESSAGE_TYPES.COMPLETE_DESCRIPTION_AUDIO : MESSAGE_TYPES.AUDIO,
        "content": { audio: recordedAudioBase64 }
      }

      onSend(data)
    }
  }, [recordedAudioBase64]);

  /*
  disable send and recording button
  */
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    const isWebsocketOpen = webSocket?.readyState == WebSocket.OPEN
    setDisabled(!isWebsocketOpen)
    setText('')
  }, [webSocket?.readyState]);
  
  const { dispatch, useAppSelector } = useRedux();
  const MessagesStates= createSelector(
    (state : any) => state.Messages,
    (state) => ({
        roomDetail: state.roomDetails
    })
  );

  // Inside your component
  const {roomDetail} = useAppSelector(MessagesStates)
  useEffect(()=>{
    setText('')
  },[roomDetail?.id])

  /*
  input text
  */
  const [text, setText] = useState<null | string>('');
  const onChangeText = (value: string) => {
    setText(value);
  };

  // Submit Message
  const onSubmit = () => {

    const lastMessage = messagesList[messagesList.length - 1]

  
    let data: any = {
      "sender_type": SENDER_TYPES.USER,
      "message_type": lastMessage.message_type === MESSAGE_TYPES.COMPLETE_DESCRIPTION_FREE_TEXT ? MESSAGE_TYPES.COMPLETE_DESCRIPTION_FREE_TEXT : MESSAGE_TYPES.FREE_TEXT,
      "content": { text }
    }

    setText("");
    if (text?.length) {
      onSend(data);
    }
  };
  let isPool = false;
  if(messagesList){
    const lastMessage = messagesList[messagesList.length - 1]
    isPool = lastMessage && (lastMessage.message_type === MESSAGE_TYPES.SIMPLE_OPTION_POLL || lastMessage.message_type === MESSAGE_TYPES.MULTIPLE_OPTIONS_POLL)
  }
  return (
    <div className="chat-input-section p-3 p-lg-4">
      <Form
        id="chatinput-form"
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="row g-0 align-items-center">
          <div className="col">
            <InputSection value={text} onChange={onChangeText} disabled={disabled || isPool} />
          </div> 
          <div className="col-auto">
          
            <EndButtons onSubmit={onSubmit}
              handleRecorderClick={handleRecorderClick}
              disabled={disabled || wait || isPool}
              waitAnswer={wait}
              mediaRecorder={mediaRecorder}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Index;
