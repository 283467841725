import React, { useState } from "react";

// interface
import { MessagesTypes } from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { MESSAGE_TYPES, SENDER_TYPES } from "../../../constants";

interface RepliedMessageProps {
  isFromMe: boolean;
  message: MessagesTypes;
  fullName: string;
}
function MultipleOptionsPoolMessage({ message, onSend, disabled }: any) {

  const [selectedOptions, setSelectedOptions] = useState(
    message.content.options.reduce((acc: any, item: any) => {
      acc[item] = false; // Initially, all checkboxes are unchecked
      return acc;
    }, {})
  );

  const onSelectOption = (option: string) => {
    setSelectedOptions({
      ...selectedOptions,
      [option]: !selectedOptions[option],
    });
  };


  const onReplyMultipleAnswer = () => {
    let data = {
      "message_type": MESSAGE_TYPES.MULTIPLE_OPTIONS_ANSWER,
      "sender_type": SENDER_TYPES.USER,
      "content": {
        "question": message.content.question,
        "options": message.content.options,
        "selected": message.content.options.filter(
          (item: string) => selectedOptions[item]
        )
      }
    }
    if (data.content.selected.length) {
      onSend(data)
    }
  }

  //const isReplyFromMe = message.meta.sender + "" === userProfile.uid + "";
  return (
    <div className="ctext-wrap-content" key={message.id}>
      <p className="mb-0 ctext-content">{message.content.question}</p>
      {message.content.question && (message.content.options.map((item: any) => <>
        <FormGroup check>
          <Input
            id={item}
            type="checkbox"
            checked={selectedOptions[item]}
            onChange={() => { onSelectOption(item) }}
            disabled={disabled}
          />
          <Label check for={item}>{item}</Label>
        </FormGroup>
      </>))}
      <br />
      <Button style={{ width: "100%" }}
        //onSubmit={(message) => onReplyMultipleAnswer(message)}
        onClick={() => onReplyMultipleAnswer()}
        className="btn btn-primary btn-sm"
        disabled={disabled}
      >Responder
      </Button>
    </div>
  );
}

export default MultipleOptionsPoolMessage;
