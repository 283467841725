import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const createRoomApi = (data: object) => {
  return api.create(url.CREATE_ROOM, data);
};
const getRoomMessagesApi = (room_id: string) => {
  return api.get(url.GET_ROOM_MESSAGES(room_id));
};
const getRoomsApi = () => {
  return api.get(url.GET_ROOMS);
};
const getRoomDetailsApi = (room_id: string) => {
  return api.get(url.GET_ROOM_DETAILS(room_id));
};

const getUuidApi = () => {
  return api.get(url.GET_UUID);
};

export {
    getRoomMessagesApi,
    getRoomsApi,
    getUuidApi,
    getRoomDetailsApi,
    createRoomApi
  };