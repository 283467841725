import React, { useEffect, useRef, useCallback, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// hooks
import { useProfile } from "../../../hooks";
import { createSelector } from "reselect";
// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import Message from "./Message";
// import Day from "./Day";

// interface
import { MessagesTypes } from "../../../data/messages";

// actions
//import { forwardMessage, deleteImage } from "../../../redux/actions";
interface ConversationProps {
  chatUserConversations: any;
  disabled: boolean
  onSend: (data: any) => void;
  messagesList: any;//Array<{text:string; time:String; meta:{receiver:string; sender: string;}}>;
}
const Conversation = ({
  chatUserConversations,
  disabled,
  onSend,
  messagesList
}: ConversationProps) => {
  // global store
  // Inside your component

  //console.log("5. CONVERSATION (pages/Dashboard/ConversationUser/Conversation.tsx)", chatUserConversations)
  const ref = useRef<any>();
  const scrollElement = useCallback(() => {
    if (ref && ref.current) {
      const listEle = document.getElementById("chat-conversation-list");
      let offsetHeight = 0;
      if (listEle) {
        offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
      }
      if (offsetHeight) {
        ref.current
          .getScrollElement()
          .scrollTo({ top: offsetHeight, behavior: "smooth" });
      }
    }
  }, [ref]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  }, []);
  useEffect(() => {
    if (messagesList) {
      scrollElement();
    }
  }, [messagesList, scrollElement]);
  return (
    <AppSimpleBar
      scrollRef={ref}
      className="chat-conversation p-3 p-lg-4 positin-relative"
    >
      <ul
        className="list-unstyled chat-conversation-list"
        id="chat-conversation-list"
      >
        {(messagesList || []).map((message: any, key: number) => {
          let isLastItem = (key + 1 == messagesList.length)
          
          return (
            <Message
              message={message}
              key={key}
              isFromMe={true}
              onSend={onSend}
              disabled={!isLastItem || disabled}
              isLastItem={isLastItem}
            />
          );
        })}
      </ul>
    </AppSimpleBar>
  );
};

export default Conversation;
