import { MessagesActionTypes } from "./types";


// common success
export const messagesApiResponseSuccess = (actionType: string, data: any) => ({
  type: MessagesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const messagesApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: MessagesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// Rooms
export const createRoom = () => ({
  type: MessagesActionTypes.CREATE_ROOM
});
export const getRoomDetails = (id: number | string) => ({
  type: MessagesActionTypes.GET_ROOM_DETAILS,
  payload: id,
});

// Recording
export const startRecordingAudio = () => ({
  type: MessagesActionTypes.START_RECORDING_AUDIO,
});

export const pauseRecordingAudio = () => ({
  type: MessagesActionTypes.PAUSE_RECORDING_AUDIO,
});

export const resumeRecordingAudio = () => ({
  type: MessagesActionTypes.RESUME_RECORDING_AUDIO,
});

export const cancelRecordingAudio = () => ({
  type: MessagesActionTypes.ON_CANCEL_RECORDING_AUDIO,
});

export const stopRecordingAudio = () => ({
  type: MessagesActionTypes.STOP_RECORDING_AUDIO,
});
export const clearAudioMessage = () => ({
  type: MessagesActionTypes.CLEAR_RECORDED_AUDIO,
});

// common success
export const startedRecordingAudio = (mediaRecorder: MediaRecorder) => ({
  type: MessagesActionTypes.STARTED_RECORDING_AUDIO,
  payload: { mediaRecorder },
});

export const recordingStatusChanged = (mediaRecorder: MediaRecorder) => ({
  type: MessagesActionTypes.RECORDING_STATUS_CHANGED,
  payload: { mediaRecorder },
});

export const stopedRecordingAudio = (mediaRecorder: MediaRecorder, recordedAudioBase64?: string|ArrayBuffer) => ({
  type: MessagesActionTypes.STOPED_RECORDING_AUDIO,
  payload: { mediaRecorder, recordedAudioBase64 },
});
export const waitResponse = (removeId: string, addId: string) =>({
  type: MessagesActionTypes.WAIT_RESPONSE,
  payload:{
    remove: removeId,
    add: addId
  }
})
// WEBSOCKET
export const loadRoomMessages = (room_id: string) => ({
  type: MessagesActionTypes.LOAD_ROOM_MESSAGES,
  payload: {room_id}
});
export const startedRoomWebSocketConnection = (room_id: string, websocket: any) => ({
  type: MessagesActionTypes.STARTED_ROOM_WEBSOCKET_CONNECTION,
  payload: {room_id, websocket}
});
export const onWebSocketClosed = () => ({type: MessagesActionTypes.ON_CLOSE_WEBSOCKET_CONNECTION})
export const ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST';
export const PUT_MESSAGE = 'PUT_MESSAGE';

interface AddMessageRequestAction {
  type: typeof ADD_MESSAGE_REQUEST;
  payload: {
    list: string[];
  };
}

interface PutMessageAction {
  type: typeof PUT_MESSAGE;
  payload: {
    list: string[];
  };
}

export type MessageActionTypes = AddMessageRequestAction | PutMessageAction;

export const addMessageRequest = (list: string[]): AddMessageRequestAction => ({
  type: ADD_MESSAGE_REQUEST,
  payload: { list }
});

export const putMessage = (list:any): PutMessageAction => ({
  type: PUT_MESSAGE,
  payload: list 
});
