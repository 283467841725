// costants
import { TABS } from "../../constants/index";

export interface MenuItemType {
  id: number;
  key: string;
  icon: string;
  tooltipTitle: string;
  className?: string;
  tabId:
    | TABS.CHAT
    | TABS.SETTINGS
}
const MENU_ITEMS: MenuItemType[] = [
  {
    id: 2,
    key: "pills-chat-tab",
    icon: "bx bx-conversation",
    tooltipTitle: "Atendimentos",
    tabId: TABS.CHAT,
  },
  // {
  //   id: 6,
  //   key: "pills-setting-tab",
  //   icon: "bx bx-cog",
  //   tooltipTitle: "Configurações",
  //   tabId: TABS.SETTINGS,
  // },
];

export { MENU_ITEMS };
