import React from "react";
import { Alert, Row, Col, Form } from "reactstrap";

// hooks
import { useRedux } from "../../hooks/index";
import { createSelector } from "reselect";
// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// // hooks
// import { useProfile } from "../../hooks";

//actions
import { userChangePassword } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";

//images
import avatar1 from "../../assets/images/users/avatar-1.jpg";

interface ChangePasswordProps {}
const ChangePassword = (props: ChangePasswordProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  // const { changepasswordError, passwordChanged, changePassLoading } =
  //   useAppSelector(state => ({
  //     passwordChanged: state.ForgetPassword.passwordChanged,
  //     changepasswordError: state.ForgetPassword.changepasswordError,
  //     changePassLoading: state.ForgetPassword.loading,
  //   }));


  const errorData = createSelector(
    (state : any) => state.ForgetPassword,
    (state) => ({
      passwordChanged: state.passwordChanged,
      changepasswordError: state.changepasswordError,
      changePassLoading: state.loading,
    })
  );
  // Inside your component
  const { passwordChanged,changepasswordError,changePassLoading} = useAppSelector(errorData);

  const resolver = yupResolver(
    yup.object().shape({
      oldPassword: yup.string().required("Por favor, digite a senha atual."),
      password: yup.string().required("Por favor, digite a nova senha."),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref("password")], "As senhas não correspondem.")
        .required("As senhas não correspondem."),
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = (values: object) => {
    dispatch(userChangePassword(values));
  };

  // const { userProfile, loading } = useProfile();

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader title="Alterar senha" />
            {changepasswordError && changepasswordError ? (
              <Alert color="danger">{changepasswordError}</Alert>
            ) : null}
            {passwordChanged ? (
              <Alert color="success">Senha alterada com sucesso</Alert>
            ) : null}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {changePassLoading && <Loader />}
              <div className="mb-3">
                <FormInput
                  label="Senha"
                  type="password"
                  name="oldPassword"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Digite a senha"
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={true}
                />
              </div>
              <div className="mb-3">
                <FormInput
                  label="Nova senha"
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Digite a nova senha"
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={false}
                />
              </div>
              <div className="mb-3">
                <FormInput
                  label="Confirmar nova senha"
                  type="password"
                  name="confirmpassword"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Confirme a nova senha"
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={true}
                />
              </div>

              <div className="text-center mt-4">
                <div className="row">
                  <div className="col-6">
                    <button className="btn btn-primary w-100" type="submit">
                      Alterar senha
                    </button>
                  </div>
                  <div className="col-6">
                    <button className="btn btn-light w-100" type="button">
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default ChangePassword;
