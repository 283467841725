import React, { useEffect } from "react";
import { createSelector } from "reselect";
import { Button, Form, Input, Spinner, UncontrolledTooltip } from "reactstrap";
// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  getDirectMessages,
  getChannels,
  changeSelectedChat,
  getChatUserDetails,
  getChatUserConversations,
  readConversation,
  loadRoomMessages,
  getRoomDetails,
  createRoom,
} from "../../../redux/actions";


// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import AddButton from "../../../components/AddButton";

import Chanels from "./Chanels";
import RecordingAlert from "../../../components/RecordingAlert";

interface IndexProps {}
const Index = (props: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const errorData = createSelector(
    (state : any) => state.Contacts,
    (props : any) => props.Chats,
    (state,props) => ({
        directMessages: props.directMessages,
        channels: props.channels,
        isChannelCreated: props.isChannelCreated,
        selectedChat: props.selectedChat,
        chatUserDetails:props.chatUserDetails,
    })
  );

  const errorData2 = createSelector(
    (state : any) => state.Messages,
    (state) => ({
        rooms: state.rooms,
        newRoomCreated: state.newRoomCreated,
        mediaRecorder: state.mediaRecorder,
        isLoading: state.isLoading,
    })
  );

  // Inside your component
  const {selectedChat} = useAppSelector(errorData);
  const {rooms, newRoomCreated, mediaRecorder, isLoading} = useAppSelector(errorData2)
  const isRecording = mediaRecorder?.state === "recording"
  // get data
  useEffect(() => {
    dispatch(getDirectMessages());
    dispatch(getChannels());
  }, [dispatch]);

  /*
  channel creation handeling
  */
 
  const onCreateRoom= () => {
    if(!isRecording){
      dispatch(createRoom())
    }else{
      RecordingAlert()
    }
    
  };
  useEffect(() => {
    if (!!newRoomCreated) {
      dispatch(getChannels());
      dispatch(getRoomDetails(newRoomCreated.id));
      dispatch(loadRoomMessages(`${newRoomCreated.id}`))
      dispatch(changeSelectedChat(newRoomCreated.id));
    }
  }, [dispatch, newRoomCreated]);

  /*
  select chat handeling :
    get conversations
    get chat user details
  */

  const onSelectChat = async (id: string | number, isChannel?: boolean) => {
    if(!isRecording){
      if (isChannel) {
        dispatch(getRoomDetails(id));
        dispatch(loadRoomMessages(`${id}`))
      } else {
        dispatch(getChatUserDetails(id));
      }
      dispatch(readConversation(id));
      dispatch(getChatUserConversations(id));
      dispatch(changeSelectedChat(id));
    }else{
      RecordingAlert()
    }
  };

  //serach recent user
  const searchUsers = () => {
    var li, a, i, txtValue: any;
    const inputValue: any = document.getElementById("serachChatUser");
    const filter: any = inputValue.value.toUpperCase();
    const ul: any = document.querySelector(".chat-room-list");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
  };

  return (
    <>
      <div>
        <div className="px-4 pt-4">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h4 className="mb-4">Atendimentos</h4>
            </div>
            <div className="flex-shrink-0">
              {!isLoading ? (<>
              <div id="add-contact">
                {/* Button trigger modal */}
                <AddButton onClick={onCreateRoom} />
              </div>
              <UncontrolledTooltip target="add-contact" placement="bottom">
                Adicionar Chamado
              </UncontrolledTooltip>
              </>) : (
                <>
                <div id="add-contact">
                  <div className="btn btn-sm justify-content-center" role="status">
                  <Spinner size="sm" color="success" />
                  </div>
                </div>
                <UncontrolledTooltip target="add-contact" placement="bottom">
                  Criando Chamado...
                </UncontrolledTooltip>
                </>
              )}
            </div>
           
          </div>
          <Form>
            <div className="input-group mb-3">
              <Input
                onKeyUp={searchUsers}
                id="serachChatUser"
                type="text"
                className="form-control bg-light border-0 pe-0"
                placeholder="Pesquise aqui.."
              />
              <Button color="light" type="button" id="searchbtn-addon">
                <i className="bx bx-search align-middle"></i>
              </Button>
            </div>
          </Form>
        </div>
        <AppSimpleBar className="chat-room-list">
          <Chanels
            channels={rooms}
            selectedChat={selectedChat}
            onSelectChat={onSelectChat}
          />
        </AppSimpleBar>
      </div>
    </>
  );
};

export default Index;
