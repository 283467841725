import React, { useState } from "react";

// interface
import { MessagesTypes } from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { MESSAGE_TYPES, SENDER_TYPES } from "../../../constants";

interface RepliedMessageProps {
  isFromMe: boolean;
  message: MessagesTypes;
  fullName: string;
}
function SimpleOptionsPoolMessage({ message, onSend, disabled }: any) {
  const onReplySimpleAnswer = (item: any, message: any) => {
    let data = {
      "message_type": MESSAGE_TYPES.SIMPLE_OPTION_ANSWER,
      "sender_type": SENDER_TYPES.USER,
      "content": {
        "question": message.content.question,
        "options": message.content.options,
        "selected": item
      }
    }
    onSend(data)
  }

  return (
    <div className="ctext-wrap-content">
      <p className="mb-0 ctext-content">{
        message.content.question
          .split("\n")
          .map((line: any, index: number) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))
    }</p>
      {message.content.question &&
        message.content.options.map((item: any) =>
          <>
            <Button
              className="mx-1 my-1 btn btn-primary btn-sm"
              onClick={() => onReplySimpleAnswer(item, message)}
              disabled={disabled}
            >
              {item}
            </Button>
          </>)
      }
    </div>
  );
}

export default SimpleOptionsPoolMessage;
