import React from "react";

import { Button, UncontrolledPopover, PopoverBody, Spinner } from "reactstrap";
interface EndButtonsProps {
  onSubmit: () => void;
  handleRecorderClick: () => void;
  disabled: boolean;
  mediaRecorder: MediaRecorder;
  waitAnswer: boolean
}
const EndButtons = ({ onSubmit, handleRecorderClick, disabled, mediaRecorder, waitAnswer }: EndButtonsProps) => {
  const isRecording = mediaRecorder?.state == "recording"
  const thereIsTarget = document.getElementById('audio-btn') !== null
  return (
    <div className="chat-input-links ms-2 gap-md-1">
      <div className="links-list-item">
        <Button
          color="none"
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          id="audio-btn"
          disabled={disabled}
          onClick={handleRecorderClick}
        >
          {isRecording ?
            <i className="bx bx-stop align-middle"></i> :
            <i className="bx bx-microphone align-middle"></i>}
        </Button>
      </div>
     {thereIsTarget && <UncontrolledPopover placement="top" target="audio-btn" isOpen={isRecording}>
        <PopoverBody>
          <div className="loader-line">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>

          <svg className="countdown-circle" width="100" height="100">
            <circle className="background" cx="50" cy="50" r="26" />
            <circle className="progress" cx="50" cy="50" r="26" />
          </svg>
        </PopoverBody>
      </UncontrolledPopover>}
      <div className="links-list-item">
        <Button
          color="primary"
          type="submit"
          disabled={disabled || isRecording}
          className="btn btn-primary btn-lg chat-send waves-effect waves-light"
        >
          {waitAnswer ? 
          <Spinner size="sm" color="light" /> : 
          <i className="bx bxs-send align-middle"></i>}
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;
