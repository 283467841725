import React from "react";
import classnames from "classnames";
import { FaCopy } from "react-icons/fa";
import { GoAlert } from "react-icons/go";

import { MESSAGE_TYPES, SENDER_TYPES } from "../../../constants";
import { Button } from "reactstrap";
import MultipleOptionsPoolMessage from "./MultipleOptionsPoolMessage";
import MultipleOptionsPoolAnswerMessage from "./MultipleOptionsPoolAnswerMessage";
import SimpleOptionsPoolMessage from "./SimpleOptionsPoolMessage";
import SimpleOptionsPoolAnswerMessage from "./SimpleOptionsPoolAnswerMessage";
import { createRoom } from "../../../redux/actions";
import { useRedux } from "../../../hooks/index";
import { toast } from "react-toastify";

const Message = ({ message, onSend, disabled, isLastItem }: any) => {
  const { dispatch } = useRedux();
  const onCreateRoom = () => {
    dispatch(createRoom());
  };

  const isFromMe = message.sender_type === SENDER_TYPES.USER;
  return (
    <li
      className={classnames("chat-list", { right: isFromMe }, { reply: false })}
    >
      <div className="conversation-list">
        <div className="user-chat-content">
          <div
            className={classnames("ctext-wrap", {
              "mb-sm-0": isLastItem,
            })}
          >
            {message.message_type === MESSAGE_TYPES.FREE_TEXT && (
              <div className="ctext-wrap-content">
                <p className="mb-0 ctext-content">
                  {message.content.text
                    .split("\n")
                    .map((line: any, index: number) => (
                      <span key={index}>
                        {isFromMe
                          ? line
                          : line.charAt(0).toUpperCase() + line.slice(1)}
                        <br />
                      </span>
                    ))}
                </p>
              </div>
            )}
            {(message.message_type === MESSAGE_TYPES.AUDIO || message.message_type === MESSAGE_TYPES.COMPLETE_DESCRIPTION_AUDIO) && (
              <div className="ctext-wrap-content">
                <p></p>
                <audio
                  className="mb-0 ctext-content"
                  src={message.content.audio}
                  controls
                  controlsList="nodownload"
                ></audio>
              </div>
            )}
            {message.message_type === MESSAGE_TYPES.SIMPLE_OPTION_POLL && (
              <SimpleOptionsPoolMessage
                message={message}
                onSend={onSend}
                disabled={disabled}
              ></SimpleOptionsPoolMessage>
            )}
            {message.message_type === MESSAGE_TYPES.MULTIPLE_OPTIONS_POLL && (
              <MultipleOptionsPoolMessage
                message={message}
                onSend={onSend}
                disabled={disabled}
              ></MultipleOptionsPoolMessage>
            )}

            {message.message_type === MESSAGE_TYPES.SIMPLE_OPTION_ANSWER && (
              <SimpleOptionsPoolAnswerMessage
                message={message}
              ></SimpleOptionsPoolAnswerMessage>
            )}
            {message.message_type === MESSAGE_TYPES.MULTIPLE_OPTIONS_ANSWER && (
              <MultipleOptionsPoolAnswerMessage
                message={message}
              ></MultipleOptionsPoolAnswerMessage>
            )}
            {message.message_type === MESSAGE_TYPES.SUCCESSFUL_CLOSURE && (
              <div className="ctext-wrap-content">
                <p>A proposta de mensagem para o texto de encerramento é:</p>
                <p className="mb-3 ctext-content">
                  {message.content.text
                    .split("\n")
                    .map((line: number, index: number) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                </p>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    navigator.clipboard.writeText(message.content.text);
                    toast.success("Texto copiado para a área de transferência");
                  }}
                >
                  Copiar texto {"  "} <FaCopy />
                </Button>
              </div>
            )}
            {message.message_type === MESSAGE_TYPES.FAILED_CLOSURE && (
              <div className="ctext-wrap-content">
                <p className="mb-3 ctext-content">{message.content.text}</p>
                <Button color="primary" size="sm" onClick={onCreateRoom}>
                  Novo atendimento
                </Button>
              </div>
            )}
            {message.message_type === MESSAGE_TYPES.ERROR && (
              <div
                className="ctext-wrap-content border-danger"
                style={{
                  border: "1px solid",
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                }}
              >
                <GoAlert
                  color="red
               "
                />
                <p className="mb-0 ctext-content text-danger ">
                  {message.content.text}
                </p>
              </div>
            )}
            {message.message_type ===
              MESSAGE_TYPES.COMPLETE_DESCRIPTION_FREE_TEXT && (
              <div className="ctext-wrap-content">
                <p className="mb-0 ctext-content">
                  {message.content.text
                    .split("\n")
                    .map((line: number, index: number) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default Message;
