// actions.ts
export const ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST';
export const PUT_MESSAGE = 'PUT_MESSAGE';

interface AddMessageRequestAction {
  type: typeof ADD_MESSAGE_REQUEST;
  payload: {
    list: string[];
  };
}

interface PutMessageAction {
  type: typeof PUT_MESSAGE;
  payload: {
    list: string[];
  };
}

export type MessageActionTypes = AddMessageRequestAction | PutMessageAction;

export const addMessageRequest = (list: string[]): AddMessageRequestAction => ({
  type: ADD_MESSAGE_REQUEST,
  payload: { list }
});

export const putMessage = (list:any): PutMessageAction => ({
  type: PUT_MESSAGE,
  payload: list 
});
