import React, { useState } from "react";

// interface
import { MessagesTypes } from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { MESSAGE_TYPES, SENDER_TYPES } from "../../../constants";

interface RepliedMessageProps {
  message: MessagesTypes;
}
function MultipleOptionsPoolAnswerMessage({ message }: any) {
  return (
    <div className="replymessage-block mb-2 d-flex align-items-start">
      <div className="flex-grow-1">
        <h5 className="conversation-name">
          {message.content.question}
        </h5>
        {message.content.question &&
          <ul>
            {message.content.selected.map((item: any) => <li>{item}</li>)}
          </ul>
        }
      </div>
    </div>
  );
}

export default MultipleOptionsPoolAnswerMessage;
