// interface
import { ChannelTypes } from "../../../data/chat";

// components
import ChatChannel from "./ChatChannel";

interface ChanelsProps {
  channels: Array<any>;
  selectedChat: string | number;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}
const Chanels = ({
  channels,
  selectedChat,
  onSelectChat,
}: ChanelsProps) => {
  return (
    <>
      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list mb-3">
          {(channels || [])
           //Ordering channels list (newst first)
          .sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          })
          .map((channel: ChannelTypes, key: number) => (
            <ChatChannel
              channel={channel}
              key={key}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
            
          ))}
        </ul>
      </div>
    </>
  );
};

export default Chanels;
