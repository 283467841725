// reducers.ts
import { MessageActionTypes, PUT_MESSAGE } from './actions';


interface Meta {
  receiver:string;
  sender: string;
}
interface Chat {
  text: string;
  time: string;
  meta: Meta;
}
interface MessageWSKTState {
  chatList: Chat[];
}

const initialState: MessageWSKTState = {
  chatList: [{
    text: '',
    time: '',
    meta: {
        receiver:'',
        sender: '',
    }
}]
};


const messageReducer = (state = initialState, action: any): MessageWSKTState => {
  switch (action.type) {
    case PUT_MESSAGE:
      return {
        ...state,
        chatList: [...state.chatList, action.payload],
      };
      
    default:
      return state; 
  }
};



export default messageReducer;
