import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { API_ROUTE_REFRESH_TOKEN, LOGOUT, TOKEN_EXPIRED, USER_LOCAL_STORAGE_TOKENS } from "./urls";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

//intercepting all requests and applying the header token
instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// intercepting to capture errors
instance.interceptors.response.use(
  function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data ? response.data : response;
  },
  async function (error: any) {
    const originalRequest = error.config

    // In "axios": "^1.1.3" there is an issue with headers, and this is the workaround.
    originalRequest.headers = JSON.parse(
      JSON.stringify(originalRequest.headers || {})
    );
    if (originalRequest.url !== 'auth-login' && error.response) {

      if (error.response.status === 401 && originalRequest._retry !== true) {
        originalRequest._retry = true;

        try {
          const refreshToken = getLocalRefreshToken();

          //Condition for the refresh token expiration
          const rs: any = error.response.data.detail !== TOKEN_EXPIRED ? await instance.post(API_ROUTE_REFRESH_TOKEN, {
            refresh: refreshToken,
          }) : (window.location.href = LOGOUT);


          updateLocalAccessToken(rs?.access);
          setAuthorization(rs?.access);
          return instance(originalRequest);
        } catch (_error: any) {
          clearLocalTokens();
          window.location.href = LOGOUT
          return Promise.reject(_error);
        }
      }
    }
    let message;
    switch (error?.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;
};
const setAuthorizationHeader = () => {
  const accessToken = getLocalAccessToken();
  setAuthorization(accessToken)
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}) => {
    return instance.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data?: {}) => {
    return instance.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data?: {}) => {
    return instance.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}) => {
    return instance.delete(url, { ...config });
  };

  /*
   file upload update method
  */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    // const config = {
    //   headers: {
    //     ...axios.defaults.headers,
    //     "content-type": "multipart/form-data",
    //   },
    // };
    // return axios.put(url, formData, config);
  };

  /*
   file upload post method
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    // const config = {
    //   headers: {
    //     ...axios.defaults.headers,
    //     "content-type": "multipart/form-data",
    //   },
    // };
    return axios.post(url, formData);
  };
};
export const isTokenValid = (token: string) => {
  if (!token) return false;

  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken?.exp < currentTime) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}
const getLoggedinUser = () => {
  const user = localStorage.getItem(USER_LOCAL_STORAGE_TOKENS);
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
export const getLocalRefreshToken = () => {
  let authUser: string | null= localStorage.getItem(USER_LOCAL_STORAGE_TOKENS);
  let refreshToken =  authUser? JSON.parse(authUser)?.refresh : ''

  return refreshToken;
}

export const getServerAcessToken = () => {
  return instance.post(API_ROUTE_REFRESH_TOKEN, {
    refresh: getLocalRefreshToken(),
  })
}
export const getLocalAccessToken = () => {
  let authUser: string | null = localStorage.getItem(USER_LOCAL_STORAGE_TOKENS);
  let access =  authUser? JSON.parse(authUser)?.access : ''
  return access;
}
export const updateLocalAccessToken = (newAccessToken: any) => {
  let user: any = localStorage.getItem(USER_LOCAL_STORAGE_TOKENS)
  let userOBJ = JSON.parse(user);
  userOBJ.access = newAccessToken;
  localStorage.setItem(USER_LOCAL_STORAGE_TOKENS, JSON.stringify(userOBJ));
}
export const clearLocalTokens = () => {
  localStorage.removeItem(USER_LOCAL_STORAGE_TOKENS);
}


export {
  APIClient,
  setAuthorization,
  setAuthorizationHeader,
  getLoggedinUser,
};
