export enum SENDER_TYPES {
    USER = "USER",
    TECHTALK = "TECHTALK",
}

export enum MESSAGE_TYPES {
    FREE_TEXT = "FREE_TEXT",
    COMPLETE_DESCRIPTION_FREE_TEXT = "COMPLETE_DESCRIPTION_FREE_TEXT",
    COMPLETE_DESCRIPTION_AUDIO = "COMPLETE_DESCRIPTION_AUDIO",
    AUDIO = "AUDIO",
    SIMPLE_OPTION_ANSWER = "SIMPLE_OPTION_ANSWER",
    SIMPLE_OPTION_POLL = "SIMPLE_OPTION_POLL",
    MULTIPLE_OPTIONS_ANSWER = "MULTIPLE_OPTIONS_ANSWER",
    MULTIPLE_OPTIONS_POLL = "MULTIPLE_OPTIONS_POLL",
    SUCCESSFUL_CLOSURE = "SUCCESSFUL_CLOSURE",
    FAILED_CLOSURE = "FAILED_CLOSURE",
    ERROR = "ERROR",
}