import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// interface
import { ChannelTypes } from "../../../data/chat";

interface ChannelProps {
  channel: any;
  selectedChat: string | number;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}

export function atendimentoName(dataStr: string): string {
  const data = new Date(dataStr);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
  const ano = data.getUTCFullYear();

  const horas = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');

  return isNaN(Number(dia)) ? 'Atendimento' : `Atendimento ${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

const ChatChannel = ({ channel, selectedChat, onSelectChat }: ChannelProps) => {
  //const unRead = channel.meta && channel.meta.unRead;
  const isSelectedChat: boolean =
    selectedChat && selectedChat === channel.id ? true : false;
  const onClick = () => {
    onSelectChat(channel.id, true);
  };

  return (
    <li className={classnames({ active: isSelectedChat })} onClick={onClick}>
      <Link to="#" className={classnames({ "unread-msg-user": false /*unRead*/ })}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title rounded-circle bg-soft-light text-dark">
              #
            </span>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-truncate mb-0">{atendimentoName(channel.created_at)}</p>
          </div>
          {/*unRead && unRead !== 0 ? 
          (
            <div className="flex-shrink-0 ms-2">
              <span className="badge badge-soft-dark rounded p-1">
                {unRead}
              </span>
            </div>
          ) : null*/}
        </div>
      </Link>
    </li>
  );
};

export default ChatChannel;
