import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";
// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  //toggleArchiveContact,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";
import TestRecorderBar from "../../../components/TestRecorderBar";
import { cancelRecordingAudio, pauseRecordingAudio, resumeRecordingAudio, startRecordingAudio, stopRecordingAudio, waitResponse } from "../../../redux/messages/actions";

interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      chatUserDetails: state.chatUserDetails,
      chatUserConversations: state.chatUserConversations,
      chats: state
    })
  );

  // Inside your component
  const { chats, chatUserDetails, chatUserConversations} = useAppSelector(errorData);

  const errorData2 = createSelector(
    (state: any) => state.Messages,
    (state) => ({
      roomDetails: state.roomDetails,
      recordedAudioBase64: state.recordedAudioBase64,
      mediaRecorder: state.mediaRecorder,
      messages: state,
      waitingResponse: state.waitingResponse
    })
  );
  // Inside your component
  const { recordedAudioBase64, mediaRecorder, messages, roomDetails, waitingResponse} = useAppSelector(errorData2);

  
  
  //const messagesList = (messages[`${chatUserDetails.id}`]) ? messages[`${chatUserDetails.id}`].messagesList: []
   // messagesList: state[`${chatUserDetails.id}`].messagesList
  // 
  const messagesList = messages[`messagesOf${roomDetails?.id}`]
  const webSocket: WebSocket = messages[`webSocketOf${roomDetails?.id}`]
  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    const isWebsocketOpen = webSocket?.readyState == WebSocket.OPEN
    setDisabled(!isWebsocketOpen)
  }, [webSocket?.readyState]);
  
  /*
  send message
  */
  const onSend = (data: any) => {
    //console.log("DATA", data)
    dispatch(waitResponse('', `${roomDetails.id}`))
    dispatch(onSendMessage(data, `${roomDetails.id}`));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };
  //console.log("4. CHAT INDEX (pages/Dashboard/ConversationUser/index.tsx)", chatUserConversations)
  const isToWaitResponse = waitingResponse.includes(`${roomDetails?.id}`)
  const isRecording = mediaRecorder?.state == "recording"
  return (
    <>
      <UserHead
        chatUserDetails={roomDetails}
        onOpenUserDetails={onOpenUserDetails}
        isChannel={isChannel}
        onDelete={onDeleteUserMessages}
        webSocket={webSocket}
        isRecording={isRecording}
      />

      <Conversation
        messagesList={messagesList}
        disabled={disabled}
        chatUserConversations={chatUserConversations}
        onSend={onSend}
      />

      <ChatInputSection
        onSend={onSend}
        onClickStart={() => dispatch(startRecordingAudio())}
        onClickStop={() => dispatch(stopRecordingAudio())}
        chatUserDetails={chatUserDetails}
        wait={isToWaitResponse}
        webSocket={webSocket}
        mediaRecorder={mediaRecorder}
        recordedAudioBase64={recordedAudioBase64}
        messagesList={messagesList}
      />
    </>
  );
};

export default Index;