export enum LAYOUT_MODES {
  LIGHT = "light",
  DARK = "dark",
}

export enum CHATS_TABS {
  ARCHIVE = "acrhive",
  DEFAULT = "default",
}

export enum TABS {
  CHAT = "pills-chat",
  SETTINGS = "pills-setting",
}
