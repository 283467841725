export enum MessagesActionTypes {
    API_RESPONSE_SUCCESS = "@@messages/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@messages/API_RESPONSE_ERROR",

    UPDATE_MESSAGES = "@@messages/UPDATE_MESSAGES",

    START_RECORDING_AUDIO = "@@messages/START_RECORDING_AUDIO",
    STOP_RECORDING_AUDIO = "@@messages/STOP_RECORDING_AUDIO",
    PAUSE_RECORDING_AUDIO = "@@messages/PAUSE_RECORDING_AUDIO",
    RESUME_RECORDING_AUDIO = "@@messages/RESUME_RECORDING_AUDIO",
    STARTED_RECORDING_AUDIO = "@@messages/STARTED_RECORDING_AUDIO",
    RECORDING_STATUS_CHANGED = "@@messages/RECORDING_STATUS_CHANGED",
    STOPED_RECORDING_AUDIO = "@@messages/STOPED_RECORDING_AUDIO",
    PLAY_RECORDED_AUDIO = "@@messages/PLAY_RECORDED_AUDIO",
    SEND_RECORDED_AUDIO = "@@messages/SEND_RECORDED_AUDIO",
    ON_CANCEL_RECORDING_AUDIO = "@@messages/ON_CANCEL_RECORDING_AUDIO",

    STARTED_ROOM_WEBSOCKET_CONNECTION = "@@messages/STARTED_ROOM_WEBSOCKET_CONNECTION",
    ON_CLOSE_WEBSOCKET_CONNECTION = "@@messages/ON_CLOSE_WEBSOCKET_CONNECTION",

    CREATE_ROOM = "@@messages/CREATE_ROOM",
    CREATE_ROOM_LOADING = "@@messages/CREATE_ROOM_LOADING",
    GET_ROOMS = "@@messages/GET_ROOMS",
    GET_ROOM_DETAILS = "@messages/GET_ROOM_DETAILS",
    LOAD_ROOM_MESSAGES = "@@messages/LOAD_ROOM_MESSAGES",

    WAIT_RESPONSE = "@@messages/WAIT_RESPONSE",
    CLEAR_RECORDED_AUDIO = "@@messages/CLEAR_RECORDED_AUDIO "
    
}

export interface MessagesState2 {
    recordedAudioURL: string;
    recordedAudioblob?: Blob
}

interface Meta {
receiver:string;
sender: string;
}
interface Chat {
text: string;
time: string;
meta: Meta;
}
interface MessageWSKTState {
chatList: Chat[];
}
export interface MessagesState {
    recordedAudioBase64: string;
    chatList: Chat[];
    messagesList: Array<any>;
}