import React, { useState } from "react";

// interface
import { MessagesTypes } from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { MESSAGE_TYPES, SENDER_TYPES } from "../../../constants";

interface RepliedMessageProps {
  message: MessagesTypes;
}
function SimpleOptionsPoolAnswerMessage({ message }: any) {
  return (
    <div className="replymessage-block mb-2 d-flex align-items-start">
      <div className="flex-grow-1">
        <h5 className="conversation-name">
          {message.content.question.split("\n").map((line: any, index: number) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </h5>
        {message.content.question && message.content.selected}
      </div>
    </div>

  );
}

export default SimpleOptionsPoolAnswerMessage;
