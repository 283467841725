import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import { changeSelectedChat } from "../../../redux/actions";

// constants
import { STATUS_TYPES } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { atendimentoName } from "../Chats/ChatChannel";
import RecordingAlert from "../../../components/RecordingAlert";
interface ProfileImageProps {
  chatUserDetails: any;
  onCloseConversation: () => any;
  onOpenUserDetails: () => any;
  isChannel: boolean;
  webSocket: WebSocket;
  isRecording: boolean;
}
const ProfileImage = ({
  chatUserDetails,
  onCloseConversation,
  onOpenUserDetails,
  isChannel,
  webSocket,
  isRecording
}: ProfileImageProps) => {
  // const fullName = !isChannel
  //   ? chatUserDetails.firstName
  //     ? `${chatUserDetails.firstName} ${chatUserDetails.lastName}`
  //     : "-"
  //   : chatUserDetails.name;
  
  const fullName = atendimentoName(chatUserDetails?.created_at)
  const shortName = "#"/*!isChannel
  ? chatUserDetails.firstName
  ? `${chatUserDetails.firstName.charAt(
    0
    )}${chatUserDetails.lastName.charAt(0)}`
    : "-"
    : "#";*/

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));

  const isOnline = false
  //chatUserDetails.status && chatUserDetails.status === STATUS_TYPES.ACTIVE;

  const members = 0//(chatUserDetails.members || []).length;
  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 d-block d-lg-none me-2">
        <Link
          to="#"
          onClick={()=>{
            if(!isRecording){
              onCloseConversation()
            }else{
              RecordingAlert()
            }
          }}
          className="user-chat-remove text-muted font-size-24 p-2"
          
        >
          <i className="bx bx-chevron-left align-middle disabled-link"></i>
        </Link>
      </div>
      <div className="flex-grow-1 overflow-hidden">
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "flex-shrink-0",
              "chat-user-img",
              "align-self-center",
              "me-3",
              "ms-0",
              { online: isOnline }
            )}
          >
            <div className="avatar-sm align-self-center">
              <span
                className={classnames(
                  "avatar-title",
                  "rounded-circle",
                  "text-uppercase",
                  "text-white",
                  colors[color]
                )}
              >
                <span className="username"> {shortName}</span>
                <span
                  className={classnames(
                    "user-status",
                    {
                      "bg-success":
                        webSocket?.readyState === WebSocket.OPEN
                    },
                    {
                      "bg-warning":
                        webSocket?.readyState === WebSocket.CONNECTING,
                    },
                    {
                      "bg-danger":
                        (webSocket?.readyState === WebSocket.CLOSED),
                    }
                  )}
                ></span>
              </span>
            </div>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h6 className="text-truncate mb-0 font-size-18">
              <Link
                to="#"
                onClick={onOpenUserDetails}
                className="user-profile-show text-reset"
              >
                {fullName}
              </Link>
            </h6>
            <p className="text-truncate text-muted mb-0">
              {isChannel ? (
                <small>{members} Members</small>
              ) : (
                <small>{""//chatUserDetails.status
                }</small>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Search = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      {/* <DropdownToggle
        color="none"
        className="btn nav-btn dropdown-toggle"
        type="button"
      >
        <i className="bx bx-search"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
        <div className="search-box p-2">
          <Input type="text" className="form-control" placeholder="Pesquisar..." />
        </div>
      </DropdownMenu> */}
    </Dropdown>
  );
};
interface MoreProps {
  onDelete: () => void;
}
const More = ({
  onDelete
}: MoreProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      {/* <DropdownToggle color="none" className="btn nav-btn" type="button">
        <i className="bx bx-dots-vertical-rounded"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
          onClick={onDelete}
        >
          Excluir <i className="bx bx-trash text-muted"></i>
        </DropdownItem>
      </DropdownMenu> */}
    </Dropdown>
  );
};


interface UserHeadProps {
  chatUserDetails: any;
  onOpenUserDetails: () => void;
  onDelete: () => void;
  isChannel: boolean;
  webSocket: any;
  isRecording: boolean
}
const UserHead = ({
  chatUserDetails,
  onOpenUserDetails,
  onDelete,
  isChannel,
  webSocket,
  isRecording
}: UserHeadProps) => {
  // global store
  const { dispatch } = useRedux();

  /*
  mobile menu chat conversation close
  */
  const onCloseConversation = () => {
    dispatch(changeSelectedChat(null));
  };

  return (
    <div className="p-3 p-lg-4 user-chat-topbar">
      <Row className="align-items-center">
        <Col sm={4} className="col-8">
          <ProfileImage
            chatUserDetails={chatUserDetails}
            onCloseConversation={onCloseConversation}
            onOpenUserDetails={onOpenUserDetails}
            isChannel={isChannel}
            webSocket={webSocket}
            isRecording={isRecording}
          />
        </Col>
        <Col sm={8} className="col-4">
          <ul className="list-inline user-chat-nav text-end mb-0">
            <li className="list-inline-item  ">
              <Search />
            </li>

            <li className="list-inline-item ">
              <More
                onDelete={onDelete}
              />
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default UserHead;
