// types
import { MessagesActionTypes } from "./types";
import { } from "./types"

export const INIT_STATE: any = {
  //directMessages: [],
  //exampleList: [],
  rooms: [],
  roomDetails: undefined,
  selectedRoom: "",
  selectedChat: "",
  recordedAudioBase64: "",
  messagesList: [],
  waitingResponse: [],
  chatList: [{
    text: '',
    time: '',
    meta: {
      receiver: '',
      sender: '',
    }
  }],
  isLoading: false,
};

const Messages = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case MessagesActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case MessagesActionTypes.RECORDING_STATUS_CHANGED:
          return {
            ...state,
            mediaRecorder: action.payload.data.mediaRecorder
          };
        case MessagesActionTypes.STOPED_RECORDING_AUDIO:

          return {
            ...state,
            mediaRecorder: action.payload.data.mediaRecorder,
            recordedAudioBase64: action.payload.data.recordedAudioBase64,
          };
        case MessagesActionTypes.UPDATE_MESSAGES:
          let data = { ...state }
          if (!action.payload.data.reset && state.hasOwnProperty(`messagesOf${action.payload.data.room_id}`)) {
            data[`messagesOf${action.payload.data.room_id}`] = [...state[`messagesOf${action.payload.data.room_id}`]]
            data[`messagesOf${action.payload.data.room_id}`] = data[`messagesOf${action.payload.data.room_id}`].concat(action.payload.data.messages)
          }
          else {
            data[`messagesOf${action.payload.data.room_id}`] = action.payload.data.messages
          }
          return data;
        case MessagesActionTypes.CREATE_ROOM:
          let roomData = { ...state, newRoomCreated: action.payload.data }
          roomData[`messagesOf${action.payload.data.id}`] = []
          return roomData;
        case MessagesActionTypes.CREATE_ROOM_LOADING:
          return { ...state, isLoading: action.payload.data }
        case MessagesActionTypes.GET_ROOMS:
          return { ...state, rooms: action.payload.data }
        case MessagesActionTypes.GET_ROOM_DETAILS:
          return {
            ...state,
            roomDetails: { ...action.payload.data, isChannel: true },
            isChannelDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        default:
          return { ...state };
      }
    case MessagesActionTypes.STARTED_ROOM_WEBSOCKET_CONNECTION:
      let data = { ...state }
      data[`webSocketOf${action.payload.room_id}`] = action.payload.websocket
      return data;
    case MessagesActionTypes.CLEAR_RECORDED_AUDIO:
      return {
        ...state,
        recordedAudioBase64: ""
      }
    case MessagesActionTypes.WAIT_RESPONSE:
      if(action.payload.add){
        return{
          ...state,
          waitingResponse:[
            ...state.waitingResponse,
            action.payload.add
          ]
        }
      }
      return {
        ...state,
        waitingResponse: state.waitingResponse.filter((id: string) => id !== action.payload.remove)
      };
    default:
      return { ...state };
  }
};

export default Messages;