import { takeEvery, fork, put, all, call, take, delay, race, cancel, takeLatest, select } from "redux-saga/effects";
import { MessagesActionTypes } from "./types";
import { clearAudioMessage, messagesApiResponseError, messagesApiResponseSuccess, onWebSocketClosed, recordingStatusChanged, startedRoomWebSocketConnection, stopRecordingAudio, stopedRecordingAudio, waitResponse } from "./actions";

import { eventChannel, EventChannel, END } from 'redux-saga';
import { addMessageRequest, putMessage } from './actions';
import { ChatsActionTypes } from '../chats/types';
import {
  createRoomApi,
  getRoomDetailsApi,
  getRoomMessagesApi,
  getRoomsApi,
  getUuidApi
} from "../../api/index"
import { showErrorNotification, showSuccessNotification } from "../../helpers/notifications";
import { APIClient, getLocalAccessToken, getServerAcessToken, isTokenValid, updateLocalAccessToken } from "../../api/apiCore";
import { authLoginApiResponseSuccess } from "../actions";
import { AuthLoginActionTypes } from "../auth/login/types";
import { SENDER_TYPES } from "../../constants";

function createEventChannel(object: any, eventName: string) {
  return eventChannel((emitter) => {
    object.addEventListener(eventName, emitter);
    return () => object.removeEventListener(eventName, emitter);
  });
}

// AUDIO

function* onStartRecord(mediaRecorder: MediaRecorder, event: any) {
  yield put(messagesApiResponseSuccess(MessagesActionTypes.RECORDING_STATUS_CHANGED, { mediaRecorder }))
}

function* onPauseRecord(mediaRecorder: MediaRecorder, event: any) {
  yield put(messagesApiResponseSuccess(MessagesActionTypes.RECORDING_STATUS_CHANGED, { mediaRecorder }))
}

function* onResumeRecord(mediaRecorder: MediaRecorder, event: any) {
  yield put(messagesApiResponseSuccess(MessagesActionTypes.RECORDING_STATUS_CHANGED, { mediaRecorder }))
}

function* onRecordDataAvailable(chunks: any, event: any) {
  chunks.push(event.data);
}

function* onStopRecord(mediaRecorder: MediaRecorder, chunks: Array<BlobPart>, stream: any, canceled: any, event: any) {
  var recordedAudioblob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });

  stream.getTracks().forEach(function (track: any) {
    track.stop();
  });
  chunks.length = 0;

  let reader = new FileReader();
  reader.readAsDataURL(recordedAudioblob);

  const loadEndChannel = eventChannel((emitter) => {
    reader.addEventListener("loadend", emitter);
    return () => reader.removeEventListener("loadend", emitter);
  });

  yield takeEvery(loadEndChannel, onLoadEnd, mediaRecorder, canceled);
  //yield put(stopedRecordingAudio(mediaRecorder, recordedAudioblob, recordedAudioURL ));
  //yield put(stopedRecordingAudio(mediaRecorder, recordedAudioblob, recordedAudioURL));
}

function* onLoadEnd(mediaRecorder: MediaRecorder, canceled: any, event: any) {
  const data = { mediaRecorder: mediaRecorder, recordedAudioBase64: canceled ? "" : event.srcElement.result }
  yield put(messagesApiResponseSuccess(MessagesActionTypes.STOPED_RECORDING_AUDIO, data))
}

function* onCancelRecording(mediaRecorder: MediaRecorder, chunks: Array<BlobPart>, stream: any, event: any) {
  stream.getTracks().forEach(function (track: any) {
    track.stop();
  });
  chunks.length = 0;
  //yield put(stopedRecordingAudio(mediaRecorder, ""));
}

function* setupRecorder(): Generator {
  try {
    let recordChunks: BlobPart[] = [];
    let stream: MediaStream;
    let mediaRecorder: MediaRecorder;
    let canceled: any;
    yield takeEvery(MessagesActionTypes.START_RECORDING_AUDIO, function* bghg(): Generator {

      stream = (yield navigator.mediaDevices.getUserMedia({ audio: true })) as MediaStream;
      mediaRecorder = new MediaRecorder(stream);

      const startChannel = createEventChannel(mediaRecorder, "start");
      const pauseChannel = createEventChannel(mediaRecorder, "pause");
      const resumeChannel = createEventChannel(mediaRecorder, "resume");
      const dataAvailableChannel = createEventChannel(mediaRecorder, "dataavailable");
      const stopChannel = createEventChannel(mediaRecorder, "stop");

      yield takeEvery(startChannel, onStartRecord, mediaRecorder);
      yield takeEvery(pauseChannel, onPauseRecord, mediaRecorder);
      yield takeEvery(resumeChannel, onResumeRecord, mediaRecorder);
      yield takeEvery(dataAvailableChannel, onRecordDataAvailable, recordChunks);
      yield takeEvery(stopChannel, onStopRecord, mediaRecorder, recordChunks, stream, canceled);
      //yield takeEvery(MessagesActionTypes.ON_CANCEL_RECORDING_AUDIO, onCancelRecording, mediaRecorder, recordChunks, stream)//() => {canceled = true; console.log("CANCELOU", canceled);mediaRecorder.stop()})//onCancelRecording, mediaRecorder, recordChunks, stream);
      
      mediaRecorder.start();
      const {task, cancel}: any =  yield race({
        task: take(MessagesActionTypes.STOP_RECORDING_AUDIO),
        cancel: delay(25000)
      })
      if(cancel){
        yield put(stopRecordingAudio())
      }
    });

    yield takeEvery(MessagesActionTypes.PAUSE_RECORDING_AUDIO, () => mediaRecorder.pause());
    yield takeEvery(MessagesActionTypes.RESUME_RECORDING_AUDIO, () => mediaRecorder.resume());
    yield takeEvery(MessagesActionTypes.STOP_RECORDING_AUDIO, () => mediaRecorder?.stop());
  } catch (error: any) {
    // TODO
  }
}

function* getAllMessages(room_id: string, reset: boolean): any {
  const roomMessages = yield call(getRoomMessagesApi, room_id);
  if (roomMessages.length) {
    yield put(messagesApiResponseSuccess(MessagesActionTypes.UPDATE_MESSAGES, { messages: roomMessages, room_id, reset }))
  } else {
    yield put(messagesApiResponseSuccess(MessagesActionTypes.UPDATE_MESSAGES, { messages: [], room_id, reset }))
  }
}

function* onOpen(room_id: string, event: any) {
  yield call(getAllMessages, room_id, true)
}

function* onMessage(room_id: string, event: any): any{
  const message = JSON.parse(event.data);
  //To create a fake delay, uncomment out the line below
  //yield delay(100)
  if(!isTokenValid(getLocalAccessToken())){
    try {
      const rs: any = yield call (getServerAcessToken)  
      updateLocalAccessToken(rs?.access);
    } catch (_error: any) {
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, true)
      );  
    } 
  }
  if(message.sender_type === SENDER_TYPES.TECHTALK){
    yield put(waitResponse(`${room_id}`, ''))
  }

  yield put(messagesApiResponseSuccess(MessagesActionTypes.UPDATE_MESSAGES, { messages: [message], room_id, reset: false }))
}
function* onSendMessage(socket: WebSocket, socket_room_id: string, action: any) {

  //console.log("Log do que est[a sendo enviado", JSON.stringify(action.payload.data))
  if (socket_room_id == action.payload.room_id) {
    socket.send(JSON.stringify(action.payload.data))
  }
  yield put(clearAudioMessage())

}
function* onCloseWebSocket(event: any): any {
  //console.log('Socket is closed. Reconnect will be attempted in 3 second.', event);
  yield put(onWebSocketClosed());
}

function* setupWebSocket(room_id: string): any {
  var socket: any;
  let response: any;
  try {

    try {
      response = yield call(getUuidApi);
    } catch (error) {
      response = '';
    }
    
    socket = new WebSocket(`${process.env.REACT_APP_WS_BASE_URL}/chat/${room_id}/?uuid=${response?.uuid}`);
    const openChannel = createEventChannel(socket, "open");
    const messageChannel = createEventChannel(socket, "message");
    const closeChannel = createEventChannel(socket, "close");

    yield takeEvery(openChannel, onOpen, room_id)
    yield takeEvery(messageChannel, onMessage, room_id)
    yield takeEvery(closeChannel, onCloseWebSocket)
    yield takeLatest(ChatsActionTypes.ON_SEND_MESSAGE, onSendMessage, socket, room_id);
    yield put(startedRoomWebSocketConnection(room_id, socket))
  } catch (error) {
    // TODO
  }
}

function* restart(room_id: string): any {
  yield delay(3000)
  var roomIsOpen = true;
  try {
    const roomDetails = yield call(getRoomDetailsApi, room_id);
    roomIsOpen = !roomDetails.closed_at;
  } catch (error) {

  }
  if (roomIsOpen) {
    yield race({
      task: call(setupWebSocket, room_id),
      cancel: take(MessagesActionTypes.ON_CLOSE_WEBSOCKET_CONNECTION)
    })
  } else {
    yield put(startedRoomWebSocketConnection(room_id, undefined))
  }
}

function* createRoom() {
  try {
    yield put(messagesApiResponseSuccess(MessagesActionTypes.CREATE_ROOM_LOADING, true));
    const response: Promise<any> = yield call(createRoomApi, { subject: "atendimento" });
    yield put(messagesApiResponseSuccess(MessagesActionTypes.CREATE_ROOM, response));
    yield call(showSuccessNotification, "Novo atendimento criado.");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(messagesApiResponseError(MessagesActionTypes.CREATE_ROOM, error));
  } finally {
    yield put(messagesApiResponseSuccess(MessagesActionTypes.CREATE_ROOM_LOADING, false));
  }
}

function* loadRoomMessages(data: any): any {
  try {
    let messagesState = yield select(state => state.Messages)
    const roomDetails = yield call(getRoomDetailsApi, data.payload.room_id);

    const roomIsOpen = !roomDetails.closed_at;
    const webSocketIsClosed = !messagesState[`webSocketOf${data.payload.room_id}`]
    const messagesNotFetched = !messagesState[`messagesOf${data.payload.room_id}`]

    if (messagesNotFetched) {
      yield call(getAllMessages, data.payload.room_id, false)
    }
    if (roomIsOpen && webSocketIsClosed) {
      yield takeEvery(MessagesActionTypes.ON_CLOSE_WEBSOCKET_CONNECTION, restart, data.payload.room_id)
      yield race({
        task: call(setupWebSocket, data.payload.room_id),
        cancel: take(MessagesActionTypes.ON_CLOSE_WEBSOCKET_CONNECTION)
      })
    }
  }
  catch (error) {
    yield call(showErrorNotification, "Não foi possível atualizar o chat.");
    yield put(
      messagesApiResponseError(MessagesActionTypes.LOAD_ROOM_MESSAGES, "")
    );
  }
}


function* getRooms() {
  try {
    const response: Promise<any> = yield call(getRoomsApi);
    yield put(messagesApiResponseSuccess(MessagesActionTypes.GET_ROOMS, response));
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(messagesApiResponseError(MessagesActionTypes.GET_ROOMS, error));
  }
}
function* getRoomDetails({ payload: id }: any): any {
  let messagesState = yield select(state => state.Messages)
  let room = messagesState.rooms.filter((room: any) => { return room.id === id })[0]
  try {
    if (!room)
    {
      room = yield call(getRoomDetailsApi, id);
    }
    yield put(
      messagesApiResponseSuccess(MessagesActionTypes.GET_ROOM_DETAILS, room)
    );
  } catch (error: any) {
    yield put(
      messagesApiResponseError(MessagesActionTypes.GET_ROOM_DETAILS, error)
    );
  }
}

export function* watchCreateRoom() {
  yield takeEvery(MessagesActionTypes.CREATE_ROOM, createRoom);
}
export function* watchGetRooms() {
  yield takeEvery(ChatsActionTypes.GET_CHANNELS, getRooms);
}
export function* watchGetRoomDetails() {
  yield takeEvery(MessagesActionTypes.GET_ROOM_DETAILS, getRoomDetails);
}
function* messagesSaga(): Generator {
  yield all([
    fork(setupRecorder),
    fork(watchGetRooms),
    fork(watchGetRoomDetails),
    fork(watchCreateRoom),
  ]);

  yield takeEvery(MessagesActionTypes.LOAD_ROOM_MESSAGES, loadRoomMessages)
}

export default messagesSaga;