import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios'
// Login Redux States
import { AuthLoginActionTypes } from "./types";
import {
  authLoginApiResponseSuccess,
  authLoginApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
// import {
//   getFirebaseBackend,
//   setLoggeedInUser,
// } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../api/index";

import { APIClient, clearLocalTokens, setAuthorization } from "../../../api/apiCore";
import { API_ROUTE_TOKEN, USER_LOCAL_STORAGE_TOKENS } from "../../../api/urls";
import { stopRecordingAudio } from "../../actions";

//const fireBaseBackend = getFirebaseBackend();
export interface Token {
  access: string,
  refresh: string,
}
interface authUser {
  config: any,
  data: Token,
  headers: any,
  request: any,
  status: number,
  statusText: string,
}
const api = new APIClient();

const fetchToken = (email: any, password: any)=>{
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  //return api.create(API_ROUTE_TOKEN, { email, password })
  return axios.post(API_ROUTE_TOKEN, { email, password })
}


function* loginUser({ payload: { user } }: any){
  const {email, password} = user;
  try{
    const response: authUser = yield call(fetchToken, email, password)     
    const {access, refresh} =  response?.data;
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, {response: response, email: email})
    );

    //localStorage.setItem(USER_LOCAL_STORAGE_TOKENS, JSON.stringify(response));
    localStorage.setItem(USER_LOCAL_STORAGE_TOKENS, JSON.stringify({access, refresh}));
    if(response.data.access){
      setAuthorization(response.data.access)
    }

  }catch(error: any){
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error)
    );
  }
}

function* logoutUser() {
  try {
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, true)
      );
      yield put(stopRecordingAudio())
      clearLocalTokens()

  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGOUT_USER, error)
    );
  }
}

function* loginSaga() {
  yield takeEvery(AuthLoginActionTypes.LOGIN_USER, loginUser);
  yield takeEvery(AuthLoginActionTypes.LOGOUT_USER, logoutUser);
  //yield takeLatest(AuthLoginActionTypes.SOCIAL_LOGIN, socialLogin);
}

export default loginSaga;
