
import { toast } from 'react-toastify';
import { FaMicrophone } from 'react-icons/fa';


const RecordingAlert = () => {
    toast(
      <div>
        <FaMicrophone  style={{
        'marginRight': '8px',
        'color': '#dc3545'
      }}/> Finalize a gravação antes de sair. 
      </div>,
      {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

};

export default RecordingAlert;
