import React from "react";
import { Navigate } from "react-router-dom";
import { getLocalAccessToken } from "../../api/apiCore";

const Index = (props: any) => {
  const isAuthorized = getLocalAccessToken() ? true : false
  return (
    <div>
      {isAuthorized ? <Navigate to="/dashboard" /> : <Navigate to="/auth-login" />}
    </div>
  );
};

export default Index;
